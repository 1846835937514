import {
  Button,
  DataTable,
  DataTableSkeleton,
  FilterableMultiSelect,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from "@carbon/react";
import { useEffect, useState } from "react";
import { AssetDto, assetsApi } from "../assets/assetsApi";
import {
  AssetTelemetryReportDto,
  assetTelemetryReportsApi,
} from "./assetTelemetryReportsApi";
import "./filterableMultiSelect.scss";

export const ReportsList = () => {
  const [reports, setReports] = useState<AssetTelemetryReportDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [assets, setAssets] = useState<AssetDto[]>([]);
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);

  useEffect(() => {
    fetchReports(selectedAssetIds);
    fetchAssets();
  }, [currentPage, pageSize]);

  const fetchReports = async (assetIds: string[]) => {
    setIsLoading(true);
    try {
      const { reports, pageMeta } =
        await assetTelemetryReportsApi.getAllAssetTelemetryReports(
          {
            page: currentPage,
            take: pageSize,
          },
          assetIds
        );

      setReports(reports);
      setTotalItems(pageMeta.itemCount);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssets = async () => {
    try {
      const assetList = await assetsApi.getAssetList();
      setAssets(assetList.assets);
    } catch (error) {
      setAssets([]);
    }
  };

  const handleDownload = async (reportId: string) => {
    try {
      const downloadUrl =
        await assetTelemetryReportsApi.generateTelemetryReportDownloadUrl(
          reportId
        );
      window.open(downloadUrl, "_blank");
    } catch (error) {
      //
    }
  };

  const handleAssetSelection = (
    selectedItems: { id: string; value: string }[]
  ) => {
    const selectedAssetIds = selectedItems.map((item) => item.id);
    setSelectedAssetIds(selectedAssetIds);
    fetchReports(selectedAssetIds);
  };

  const filterAssets = (
    items: { id: string; value: string }[],
    {
      inputValue,
      itemToString,
    }: {
      inputValue: string | null;
      itemToString: (item: { id: string; value: string }) => string;
    }
  ): { id: string; value: string }[] => {
    const itemsToReturn = !inputValue
      ? items
      : items.filter((item) =>
          itemToString(item).toLowerCase().includes(inputValue.toLowerCase())
        );

    return [...itemsToReturn];
  };

  const headers = [
    { key: "name", header: "Report Name" },
    { key: "assetName", header: "Asset Name" },
    { key: "deviceId", header: "Device ID" },
    { key: "reportDate", header: "Report Date" },
  ];

  if (isLoading) {
    return <DataTableSkeleton headers={headers} />;
  }

  return (
    <>
      <DataTable rows={reports} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <TableContainer title="Telemetry Reports">
            <TableToolbar size="lg">
              <TableToolbarContent>
                <FilterableMultiSelect
                  className="filterable-multi-select"
                  id="asset-filter"
                  filterItems={filterAssets}
                  placeholder="Select asset"
                  itemToString={(item) => (item ? item.value : "")}
                  items={assets.map((asset) => ({
                    id: asset.id,
                    value: asset.name,
                  }))}
                  onChange={(event) =>
                    handleAssetSelection(event.selectedItems)
                  }
                  selectionFeedback="top-after-reopen"
                  selectedItems={selectedAssetIds.map((id) => ({
                    id,
                    value: assets.find((asset) => asset.id === id)?.name || "",
                  }))}
                />
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      {...getHeaderProps({ header, isSortable: true })}
                      key={header.key}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                  <TableHeader>Actions</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow {...getRowProps({ row })} key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>
                      <Button
                        size="sm"
                        onClick={() => handleDownload(row.id)}
                        kind="primary"
                      >
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <Pagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={[5, 10, 20, 50]}
        page={currentPage}
        onChange={({ page, pageSize }) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
      />
    </>
  );
};
