import { PageMetaDto } from "../../common/paginate/page-meta.dto";
import { PageOptionsDto } from "../../common/paginate/page-options.dto";
import { PaginationOrder } from "../../common/paginate/pagination-order.enum";
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGINATION_TAKE,
} from "../../common/paginate/pagination.constants";
import { apiClient } from "../../components/api/apiClient";
import { IDto } from "../../components/api/dtos";

export interface AssetTelemetryReportsReponse {
  reports: AssetTelemetryReportDto[];
  pageMeta: PageMetaDto;
}

export interface AssetTelemetryReportDto extends IDto {
  name: string;
  assetId: string;
  deviceId: string;
  reportDate: Date;
  assetName: string;
}

export const assetTelemetryReportsApi = {
  async getAllAssetTelemetryReports(
    pageOptionsDto?: PageOptionsDto,
    assetIds?: string[]
  ): Promise<AssetTelemetryReportsReponse> {
    const queryParams = {
      order: pageOptionsDto?.order || PaginationOrder.DES,
      page: Number(pageOptionsDto?.page) || DEFAULT_PAGE_NO,
      take: Number(pageOptionsDto?.take) || DEFAULT_PAGINATION_TAKE,
      assetIds,
    };

    const response = await apiClient.get("/asset-telemetry-reports", {
      params: queryParams,
    });
    return response.data;
  },

  async generateTelemetryReportDownloadUrl(reportId: string): Promise<string> {
    const response = await apiClient.post("/asset-telemetry-reports/download", {
      reportId,
    });
    return response.data.presignedUrl;
  },
};
