import PageTitle from "../../common/PageTitle";
import { ReportsList } from "./ReportsList";

export const ReportHomePage = () => {
  return (
    <div>
      <PageTitle pageTitle={"Reports"} />
      <ReportsList />
    </div>
  );
};
